import { Avatar, Dialog, Tab, Tabs, TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { User } from "../../../models/Interface";
import { WorkspaceWebService } from "../../../services/WorkspaceWebService";
import { AvatarIntials } from "../../../utils/Helpers";
import Button from "../../atoms/Button";
import { ariaControls, CustomTabPanel } from "../../atoms/CustomTabs";
import InputV3 from "../../atoms/InputV3";
import { EnhancedTable } from "../../atoms/Table";
import UserAvatar from "../../atoms/UserAvatar";
import { useAuth } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";
import InviteUsersForm from "../../molecules/InviteUsersForm";
import { useNotification } from "../../NotificationContext";

const Settings = () => {
	const { currentWorkspace, changeWorkspace, user } = useAuth();
	const [rows, setRows] = useState<any>([]);
	const [open, setOpen] = useState(false);
	const { showLoading, hideLoading, isLoading } = useLoading();
	const { showSuccessMessage, showErrorMessage } = useNotification();
	function createData(row: User) {
		return { data: { ...row } };
	}
	const mapper = (rows: Array<User>) => {
		return rows.map((el: User) => createData(el));
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		fetchWorkspace();
		setOpen(false);
	};

	const fetchWorkspace = () => {
		fetchLatestWorkspaceData();
		if (currentWorkspace?.id) {
			showLoading();
			WorkspaceWebService.fetchWorkspaceById(currentWorkspace?.id)
				.then((workspace: any) => {
					if (workspace) {
						changeWorkspace(workspace);
						const r = mapper(workspace.members);
						setRows(r);
					}
				})
				.catch((err) => {
					console.log(err);
					showErrorMessage("Unable to fetch data");
				})
				.finally(() => {
					hideLoading();
				});
		}
	};

	useEffect(() => {
		fetchLatestWorkspaceData();
	}, []);

	const fetchLatestWorkspaceData = async () => {
		if (currentWorkspace?.id) {
			const r = mapper(currentWorkspace.members);
			setRows(r);
		}
	};

	const header = [
		{
			id: "userId",
			label: "User",
			render: (item: User) => (
				<div className='g-display-flex--xs' style={{ justifyContent: "space-between", alignItems: "center" }}>
					<div className='g-display-flex--xs' style={{ alignItems: "center" }}>
						<Avatar src={item.avatar ?? ""} />
						<div className='g-margin-l-10--xs'>
							<p className='g-margin-b-0--xs g-font-size-16--xs g-font-weight--600'>
								{item.firstName} {item.lastName ?? ""}
							</p>
							<span>{item.email}</span>
						</div>
					</div>
					<i className='material-symbols-sharp'>more_horiz</i>
				</div>
			),
		},
		{
			id: "role",
			label: "Role",
			render: (item: any) => item?.role,
		},
	];

	const navigate = useNavigate();

	const methods = useForm({ mode: "onBlur" });

	const [value, setValue] = useState(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<>
			<div className='g-padding-x-20--xs g-margin-t-30--xs'>
				<div className='g-display-flex--xs g-margin-b-20--xs' style={{ justifyContent: "space-between" }}>
					<h3>Account & Settings</h3>
				</div>
				<div className='g-display-flex--xs' style={{ justifyContent: "space-between" }}>
					<div>
						<Tabs value={value} onChange={handleChange} variant='standard' scrollButtons='auto' aria-label='basic tabs example'>
							<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='My Account' {...ariaControls(0)} />
							<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='Workspace' {...ariaControls(1)} />
							<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='Members' {...ariaControls(2)} />
							<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='Subscription' {...ariaControls(1)} />
						</Tabs>
					</div>
				</div>
				<CustomTabPanel value={value} index={0}>
					<div className='g-padding-x-20--xs g-margin-t-30--xs' style={{ width: "60%" }}>
						<div>
							<div className='g-display-flex--xs g-margin-b-10--xs' style={{ alignItems: "center" }}>
								<div>
									<UserAvatar size={75}>{user.firstName[0]}</UserAvatar>
								</div>
								<div className='g-margin-l-10--xs  g-width-100-percent--xs'>
									<InputV3
										style={{ borderColor: "var(--dark-light)" }}
										label='Preferred Name'
										value={`${user.firstName} ${user.lastName}`}
										name={user.firstName}
									/>
								</div>
							</div>
							<p className='g-font-size-14--xs g-font-weight--400 g-color--dark-light'>Add photo</p>
						</div>
						<div className='g-margin-t-30--xs g-margin-b-10--xs'>
							<h5>Account security</h5>
						</div>
						<div className='g-display-flex--xs g-margin-b-10--xs' style={{ alignItems: "center", justifyContent: "space-between" }}>
							<div>
								<div className='g-font-weight--700 g-font-size-15--xs'>Email</div>
								<div className='g-font-size-14--xs g-color--dark-light'>{user.email}</div>
							</div>
							<div>
								<Button radius='3' text='Change Email' variant='bordered' />
							</div>
						</div>
						<div className='g-display-flex--xs g-margin-b-10--xs' style={{ alignItems: "center", justifyContent: "space-between" }}>
							<div>
								<div className='g-font-weight--700 g-font-size-15--xs'>Password</div>
								<div className='g-font-size-14--xs g-color--dark-light'>Set an permanent password to login to your account</div>
							</div>
							<div>
								<Button radius='3' text='Change Password' variant='bordered' />
							</div>
						</div>
						<div className='g-display-flex--xs g-margin-b-10--xs' style={{ alignItems: "center", justifyContent: "space-between" }}>
							<div>
								<div className='g-font-weight--700 g-font-size-15--xs g-color--red'>Delete my account</div>
								<div className='g-font-size-14--xs g-color--dark-light'>Permanently delete the account and remove access from all workspaces</div>
							</div>
							<div>
								<Button size='xs' radius='3' icon='chevron_right' variant='text' />
							</div>
						</div>
					</div>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					<div className='g-padding-x-20--xs g-margin-t-30--xs' style={{ width: "60%" }}>
						<div>
							<div className='g-display-flex--xs ' style={{ alignItems: "start" }}>
								<div>
									<UserAvatar size={75}>{AvatarIntials(currentWorkspace?.name || "AB")}</UserAvatar>
									<p className='g-font-size-14--xs g-font-weight--400 g-color--dark-light g-margin-t-10--xs'>Add icon</p>
								</div>
								<FormProvider {...methods}>
									<div className='g-margin-l-10--xs g-width-100-percent--xs'>
										<div className='g-margin-b-10--xs'>
											<InputV3 style={{ borderColor: "var(--dark-light)" }} label='Workspace Name' value={`${currentWorkspace?.name}`} name={"Name"} />
										</div>
										<div className='g-margin-b-0--xs'>
											<div className='g-font-weight--700 g-margin-b-5--xs'>Description</div>
											<TextareaAutosize
												className='s-form-v3__input g-padding-y-10--xs g-width-100-percent--xs'
												style={{ borderColor: "var(--dark-light)" }}
												value={`${currentWorkspace?.description}`}
												minRows={2}
												name={"Description"}
											/>
										</div>
									</div>
								</FormProvider>
							</div>
						</div>
						<div className='g-margin-t-30--xs g-margin-b-10--xs'>
							<h5>Danger Zone</h5>
						</div>
						<div className='g-display-flex--xs g-margin-b-20--xs' style={{ alignItems: "center" }}>
							<div>
								<Button radius='3' text='Leave workspace' variant='brd' />
							</div>
							<div className='g-margin-l-10--xs'>
								<Button radius='3' text='Delete entire workspace' variant='contained' />
							</div>
						</div>
					</div>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={2}>
					<div className='g-padding-x-20--xs g-margin-t-0--xs'>
						<div className='g-display-flex--xs g-margin-b-20--xs' style={{ width: "100%", justifyContent: "flex-end" }}>
							<div>
								<Button
									style={{ height: "2rem" }}
									onClick={() => handleClickOpen()}
									text='Invite Members'
									icon='add'
									radius={4}
									variant='contained'
									size='xs'
									color='primary'></Button>
							</div>
						</div>
						<Dialog fullWidth open={open} onClose={handleClose}>
							<InviteUsersForm />
						</Dialog>
						{!isLoading && rows && rows.length > 0 && <EnhancedTable rows={rows} columns={header} paginationOptions={[20]}></EnhancedTable>}
					</div>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={3}></CustomTabPanel>
			</div>
		</>
	);
};

export default Settings;
